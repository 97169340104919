<template>
  <main class="project-view-container" v-if="units.length">
    <p class="-ltb title">Project View</p>

    <section class="content-container">
      <section class="charts-container">
        <div class="simple-chart-wrapper">
          <StagesProgress :stages="stages" :units="units" />
        </div>

        <div class="simple-chart-wrapper">
          <StackedBarChart />
        </div>
        <div class="simple-chart-wrapper">
          <SimpleChart
            :title="chart.title"
            :count="chart.count"
            :pvalue="chart.pvalue"
            :rating="chart.rating"
            :dataValues="chart.dataValues"
            :height="180"
          />
        </div>
      </section>

      <!--if project has units show this -->
      <section class="units-list-container" v-if="units.length">
        <p
          class="-xltb"
          style="text-align: left; font-family: 'Montserrat-Bold'"
        >
          Units
        </p>
        <UnitsList :units="units" @deleteUnit="deleteUnit" />
      </section>

      <section class="unit-zero-container" v-else>
        <ProjectViewList />
      </section>
    </section>
  </main>
  <main class="no-units-wrapper" v-else>
    <div class="img-wrapper">
      <ThumbnailNoUnit />
    </div>
  </main>
</template>

<script>
import StagesProgress from "../components/units/StagesProgress.vue";
import SimpleChart from "../components/charts/SimpleChart.vue";
import StackedBarChart from "../components/charts/StackedBarChart.vue";
import ProjectViewList from "../components/units/ProjectViewList.vue";
import UnitsList from "../components/units/UnitsList.vue";
import ThumbnailNoUnit from "../components/dynamicThumbnails/ThumbnailNoUnit.vue";
export default {
  props: ["units", "stages"],
  components: {
    StagesProgress,
    SimpleChart,
    StackedBarChart,
    ProjectViewList,
    UnitsList,
    ThumbnailNoUnit,
  },
  data() {
    return {
      chart: {
        title: "Total Members",
        count: 58,
        pvalue: "40%",
        rating: true,
        dataValues: [31, 40, 28, 58],
      },
    };
  },
  methods: {
    deleteUnit(id) {
      this.$emit("deleteUnit", id);
    },
  },
};
</script>

<style scoped>
* {
  --charts-container-gap: 16px;
}

.project-view-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 8px;
  gap: 8px;
}

.no-units-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  user-select: none;
  pointer-events: none;
}

.title {
  font-family: "Montserrat-Bold";
  font-size: 32px;
  white-space: nowrap;
  text-align: left;
}

.content-container {
  grid-column: 1 / span 4;
  border-radius: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 1rem;
  padding-top: 8px;
}

.charts-container {
  grid-column: 1 / span 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: var(--charts-container-gap);
  overflow: hidden;
  padding: 2px;
  /* border: 1px solid red; */
}

.simple-chart-wrapper {
  background: var(--white1);
  min-height: 220px;
  max-height: 220px;
  border-radius: 8px;
  padding-block: 8px;
  padding-inline: 16px;
  border: 1px solid var(--gray6);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}

.simple-chart-wrapper:nth-child(1) {
  min-width: 240px;
  max-width: 240px;
}

.simple-chart-wrapper:nth-child(2) {
  width: 60%;
  min-width: 240px;
}

.simple-chart-wrapper:nth-child(3) {
  width: 40%;
  min-width: 240px;
}

.units-list-container {
  grid-column: 1 / span 4;
}

.unit-zero-container {
  grid-column: 1 / span 3;
}

.img-wrapper {
  position: relative;
  width: max(80vmin, 340px);
  height: max(80vmin, 340px);
  /* border-radius: 50%;
  background: var(--white1); */
  display: flex;
  z-index: -1;
}

.img-wrapper img {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 1365px) {
  .simple-chart-wrapper:nth-child(3) {
    display: none;
  }
}

@media screen and (max-width: 1279px) {
  .project-view-container {
    display: grid;
    height: auto;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    /* grid-template-rows: repeat(12, 1fr); */
    gap: 2rem;
  }

  .no-units-wrapper {
    display: none;
  }

  .charts-container {
    grid-column: 1 / span 4;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: var(--charts-container-gap);
  }

  .title {
    grid-column: 2 / span 2;
    justify-self: center;
  }

  .unit-zero-container {
    grid-column: 1 / span 4;
  }

  .img-wrapper {
    position: relative;
    grid-column: 2 / span 2;
  }
}

@media screen and (max-width: 1023px) {
  .charts-container {
    grid-column: 1 / span 4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
    width: 100%;
    height: auto;
  }

  .simple-chart-wrapper {
    box-shadow: none;
  }

  .simple-chart-wrapper:nth-child(2) {
    width: 100%;
  }
}

@media screen and (max-width: 949px) {
  .content-container {
    grid-column: span 4;
    /* background: var(--white1); */
    border-radius: 16px;
    margin-bottom: 8px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    padding: 32px;
  }

  .simple-chart-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 849px) {
  .top-container {
    min-height: 40px;
  }
}

@media screen and (max-width: 599px) {
  .content-container {
    border-radius: 4px;
  }
}

@media screen and (max-width: 430px) {
  .content-container {
    padding: 16px;
  }

  .simple-chart-wrapper {
    width: 100%;
  }
}
</style>
