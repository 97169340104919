<template>
  <main class="units-view" v-if="API_REQUEST_STATE">
    <section class="units-sidenav">
      <div class="project-info">
        <ProjectInfo :unitsLength="units.length" />
      </div>

      <div class="new-unit">
        <NewUnit />
      </div>

      <div class="project-view-summary">
        <ProjectSubMenu />
      </div>
    </section>

    <section class="units-content-container">
      <div :class="units.length ? 'project-view' : 'no-units-view'">
        <ProjectView
          :units="units"
          :stages="stages"
          v-if="stages.length"
          @deleteUnit="deleteUnit"
        />
      </div>

      <div class="right-side">
        <SkipUnitCreation v-if="!units.length" />
      </div>
    </section>
  </main>
</template>

<script>
import ProjectInfo from "@/components/units/ProjectInfo.vue";
import SearchBar from "../components/utils/BaseSearchBar.vue";
import NewUnit from "../components/units/NewUnit.vue";
import SkipUnitCreation from "../components/units/SkipUnitCreation.vue";
import ProjectSubMenu from "../components/projects/ProjectSubMenu.vue";
import ProjectView from "../views/ProjectView.vue";
import { getProject } from "../services/requests/get/projects";
export default {
  components: {
    ProjectInfo,
    SearchBar,
    NewUnit,
    ProjectSubMenu,
    ProjectView,
    SkipUnitCreation,
  },
  data() {
    return {
      PROJECTID: parseInt(this.$route.params.id),
      units: [],
      stages: [],
      API_REQUEST_STATE: false,
    };
  },
  async created() {
    const res = await getProject(this.PROJECTID);

    if (res.status != 200) return;
    this.setUnits(res.data.units);

    console.log("units: ", this.units);

    //DELETE ME AFTER PULL
    this.units.forEach((unit) => {
      unit.stage_value = unit.current_stage.stage_value;
      unit.template = "Building"; //falta esta propriedade do backend
      console.log(unit);
    });
    //#####################
    await this.setStages(res.data.stages); //remove await after api is done

    this.API_REQUEST_STATE = true;
  },
  methods: {
    async setStages(stagesArray) {
      this.stages = stagesArray;
      //UNCOMMENT ME AFTER API IS DONE NAD REMOVE ASYNC AWAIT
      //this.$store.dispatch("stages", this.stages);

      //*** DELETE ME AFTER API IS DONE ***//
      const module = await import("../assets/dummyDB/getStages");
      const tempStages = module.getStages();
      this.$store.dispatch("stages", tempStages);
      console.log(this.$store.getters.stages);
      //******//

      console.log("stages set successfully: ", this.$store.getters.stages);
    },
    setUnits(unitsArray) {
      this.units = unitsArray;
      console.log("units set successfully");
    },
    deleteUnit(id) {
      this.units = this.units.filter((unit) => unit.id != id);
    },
  },
};
</script>

<style scoped>
.units-view {
  display: flex;
  width: 100%;
  gap: 2rem;
  padding-inline: 4px;
  padding-top: 4px;
  --sidenav-width: 240px;
}

.units-sidenav {
  width: var(--sidenav-width);
  display: flex;
  flex-flow: column;
  gap: 1rem;
}

.project-info {
  grid-column: 1 / span 1;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  gap: 16px;
  padding-top: 4px;
  align-self: flex-start;
  position: relative;
  z-index: 0;
}

.units-content-container {
  display: grid;
  width: 100%;
  gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
  padding-inline: 4px;
  padding-top: 4px;
  overflow: auto;
}

.project-view {
  grid-column: 1 / span 6;
  grid-row: 1 / span 12;
}

.no-units-view {
  grid-column: 1 / span 4;
  grid-row: 1 / span 12;
}

.right-side {
  grid-column: 6 / span 1;
  padding-right: 8px;
  grid-row: 1 / span 12;
  min-width: 220px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  padding-top: 4px;
  gap: 8px;
  /* overflow: auto; */
}

.project-date-container {
  align-self: flex-end;
}

@media screen and (max-width: 1279px) {
  .units-view {
    flex-flow: column;
  }

  .units-sidenav {
    width: 100%;
    display: flex;
    gap: 1rem;
  }

  .project-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .units-content-container {
    display: grid;
    width: 100%;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
    padding-inline: 4px;
    padding-top: 4px;
    overflow: auto;
  }

  .project-view {
    width: 100%;
    align-self: center;
  }

  .right-side {
    position: relative;
    order: 2;
    width: 100%;
    justify-self: start;
    align-items: start;
  }

  .project-view-summary {
    order: 6;
    align-self: center;
  }

  .delete-unit {
    order: 7;
  }

  .new-unit {
    align-self: center;
  }

  .delete-unit {
    align-self: center;
  }
}

@media screen and (max-width: 767px) {
  .project-view {
    width: 100%;
  }

  .right-side {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .units-view {
    padding: 0;
    margin: 0;
  }
}
</style>
