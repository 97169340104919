<template>
  <main class="list-container">
    <ul>
      <li
        class="-str"
        v-for="link in links"
        :key="link"
        @click="goToPage('ProjectDetails')"
      >
        <div class="-str">
          {{ link.slug }}
        </div>
      </li>
    </ul>
  </main>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          name: "ProjectDetails",
          slug: "Project Details",
        },
        {
          name: "SpecifyTemplate",
          slug: "Specify Template",
        },
        {
          name: "Manage Members",
          slug: "Manage Members",
        },
        {
          name: "Workflow Settings",
          slug: "Workflow Settings",
        },
        {
          name: "Squads",
          slug: "Squads",
        },
      ],
    };
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name: name });
    },
  },
};
</script>

<style scoped>
ul {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  width: 240px;
  border-radius: 4px;
  user-select: none;
  background: white;
}
li {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 240px;
  cursor: pointer;
  border-bottom: 1px solid var(--gray6);
  transition: all 200ms;
}
li:last-child {
  border: none;
}
hr {
  border: none;
  border-top: 1px solid var(--gray5);
}
.-str:last-child > hr {
  display: none;
}
li:hover {
  letter-spacing: 1px;
  background: var(--secondary-highlight);
}
</style>
