<template>
  <main class="table-container">
    <table>
      <thead>
        <tr class="-xstb t-header">
          <th @click="sort('byOrgs')">Organization</th>
          <th @click="sort('byPoc')">Point of Contact</th>
        </tr>
      </thead>
      <tbody v-for="item in tableContent" :key="item">
        <tr class="t-body">
          <td>
            <div class="flex-row">
              <DynaBadge
                :picturePath="item.organization.imagePath"
                :first_name="item.organization.name"
              />

              <p class="-xstb">{{ item.organization.name }}</p>
            </div>
          </td>

          <td nowrap>
            <div class="flex-row">
              <DynaBadge
                :picturePath="item.poinOfContact.imagePath"
                :first_name="item.poinOfContact.first_name"
                :last_name="item.poinOfContact.last_name"
              />
              <div class="flex-column gap4">
                <p class="-xstr">
                  {{ item.poinOfContact.first_name }}
                  {{ item.poinOfContact.last_name }}
                </p>
                <p class="-xstb">{{ item.organization.email }}</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </main>
</template>

<script>
import DynaBadge from "../utils/DynaBadge.vue";
export default {
  components: { DynaBadge },
  data() {
    return {
      tableContent: [
        {
          organization: {
            name: "B&M",
            email: "management@bm.net",
            imagePath: require("../../assets/temp/orgs/bm.png"),
          },
          tasksCompleted: "70%",
          poinOfContact: {
            first_name: "Andrew",
            last_name: "Armstrong",
            imagePath: require("../../assets/temp/admin.png"),
          },
        },
        {
          organization: {
            name: "IM Architecture",
            email: "helpdesk@imarch.uk",
            imagePath: require("../../assets/temp/orgs/imarch.png"),
          },
          tasksCompleted: "60%",
          poinOfContact: {
            first_name: "Sophia",
            last_name: "Holland",
            imagePath: require("../../assets/temp/users/sophia_holland.png"),
          },
        },
        {
          organization: {
            name: "Brooks & Sons",
            email: "support@brooks.com",
            imagePath: require("../../assets/temp/orgs/bs.png"),
          },
          tasksCompleted: "80%",
          poinOfContact: {
            first_name: "Mark",
            last_name: "Green",
            imagePath: require("../../assets/temp/users/mark_green.png"),
          },
        },
        {
          organization: {
            name: "RS Prime",
            email: "general@rsp.com",
            imagePath: require("../../assets/temp/orgs/rs.png"),
          },
          tasksCompleted: "55%",
          poinOfContact: {
            first_name: "Mark",
            last_name: "Thompson",
            imagePath: require("../../assets/temp/users/mark_thompson.png"),
          },
        },
        {
          organization: {
            name: "Zafir",
            email: "support@zafir.com",
            imagePath: require("../../assets/temp/orgs/zafir.png"),
          },
          tasksCompleted: "74%",
          poinOfContact: {
            first_name: "Jessica",
            last_name: "Smith",
            imagePath: "",
          },
        },
        {
          organization: {
            name: "Norcraft",
            email: "general@nocraft.net",
            imagePath: require("../../assets/temp/orgs/norcraft.png"),
          },
          tasksCompleted: "82%",
          poinOfContact: {
            first_name: "Leighton",
            last_name: "Green",
            imagePath: "",
          },
        },
        {
          organization: {
            name: "TNS",
            email: "support@tns.com",
            imagePath: require("../../assets/temp/orgs/tns.png"),
          },
          tasksCompleted: "69%",
          poinOfContact: {
            first_name: "Alan",
            last_name: "Davis",
            imagePath: "",
          },
        },
        {
          organization: {
            name: "Siterra",
            email: "general@siterra.com",
            imagePath: require("../../assets/temp/orgs/siterra.png"),
          },
          tasksCompleted: "49%",
          poinOfContact: {
            first_name: "Ralph",
            last_name: "Ward",
            imagePath: "",
          },
        },
        {
          organization: {
            name: "D&F",
            email: "support@dnf.com",
            imagePath: require("../../assets/temp/orgs/df.png"),
          },
          tasksCompleted: "89%",
          poinOfContact: {
            first_name: "Robert",
            last_name: "Hunt",
            imagePath: require("../../assets/temp/users/robert_hunt.png"),
          },
        },
        {
          organization: {
            name: "BSafety",
            email: "general@bsafety.com",
            imagePath: require("../../assets/temp/orgs/bsafe.png"),
          },
          tasksCompleted: "58%",
          poinOfContact: {
            first_name: "Jimmy",
            last_name: "Hopkins",
            imagePath: require("../../assets/temp/users/jimmy_hopkins.png"),
          },
        },
        {
          organization: {
            name: "Gradient",
            email: "support@gradient.com",
            imagePath: require("../../assets/temp/orgs/gradient.png"),
          },
          tasksCompleted: "57%",
          poinOfContact: {
            first_name: "Elizabeth",
            last_name: "Fox",
            imagePath: "",
          },
        },
        {
          organization: {
            name: "Niteroica",
            email: "helpdesk@niteroica.com",
            imagePath: require("../../assets/temp/orgs/niteroica.png"),
          },
          tasksCompleted: "77%",
          poinOfContact: {
            first_name: "Harris",
            last_name: "Marshall",
            imagePath: require("../../assets/temp/users/harris_marshall.png"),
          },
        },
      ],

      upics: [
        "https://randomuser.me/api/portraits/men/32.jpg",
        "https://randomuser.me/api/portraits/women/65.jpg",
        "https://randomuser.me/api/portraits/women/63.jpg",
        "https://randomuser.me/api/portraits/men/83.jpg",
        "https://randomuser.me/api/portraits/men/79.jpg",
      ],
    };
  },
  methods: {
    sort(itemsToSort) {
      switch (itemsToSort) {
        case "byOrgs":
          //sort this.tableContent array by organization name
          this.tableContent.sort((a, b) => {
            if (a.organization.name < b.organization.name) {
              return -1;
            }
            if (a.organization.name > b.organization.name) {
              return 1;
            }
            return 0;
          });

          break;
        case "byPoc":
          this.tableContent.sort((a, b) => {
            if (a.poinOfContact.first_name < b.poinOfContact.first_name) {
              return -1;
            }
            if (a.poinOfContact.first_name > b.poinOfContact.first_name) {
              return 1;
            }
            return 0;
          });
          break;

        default:
          break;
      }
    },
  },
  computed: {
    filteredUpics() {
      return this.upics.slice(0, 4);
    },
    unshownUsers() {
      let counter = 0;

      for (let index = 0; index < this.upics.length; index++) {
        counter++;
      }
      counter = counter - 4;
      return counter;
    },
  },
};
</script>

<style scoped>
.table-container {
  position: relative;
  width: 100%;
  /* box-shadow: var(--myBoxShadow); */
  border-radius: 4px;
  max-height: max(540px, 59vh);
  overflow: auto;
}

table {
  width: 100%;
  max-height: calc(100% - 60px);
  border-collapse: collapse;
  text-align: left;
  position: relative;
  overflow-x: auto;
}

.t-header {
  height: 40px;
  /* background: var(--white1); */
  /* color: var(--white1); */
  /* background-image: linear-gradient(to right,
      var(--primary) 0%,
      #004e92 51%,
      var(--primary) 100%);
  background-size: 500% auto; */
  position: sticky;
  top: 0;
  z-index: 1;
  outline: 1px solid var(--gray5);
  background: var(--white1);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);
}

th {
  user-select: none;
  cursor: pointer;
  padding-inline: 1rem;
  border-bottom: 1px solid var(--gray5);
}

td {
  padding-inline: 1rem;
  height: 60px;
  border-bottom: 1px solid var(--gray6);
}

.t-body:nth-child(even) {
  background-color: #f9fafb;
}

.company-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url("https://www.itjobs.pt/empresa/bimms/logo/social");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.progressbar {
  height: 100%;
  width: 70%;
  border-radius: 8px;
  background: var(--secondary);
}

/*ICONS*/
i {
  transform: scale(0.55);
}

i:hover {
  transform: scale(0.7);
  cursor: pointer;
}

.flex-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

@media screen and (max-width: 1280px) {
  .table-container {
    position: relative;
    width: 100%;
    /* height: auto; */
    box-shadow: var(--myBoxShadow);
    border-radius: 4px;
    overflow-x: auto;
  }
}
</style>
