<template>
  <main class="project-units-container">
    <section
      class="unit-container"
      :class="units.length < 3 ? 'justify-start' : 'justify-center'"
      v-for="unit in units"
      :key="unit"
    >
      <div class="unit-header">
        <Unit :unit="unit" @deleteUnit="deleteUnit" />
        <div class="u-current-stage">
          <UnitCurrentStage :unit="unit" />
        </div>
      </div>

      <div class="flex unit-content-container">
        <ProjectOverviewTable />
      </div>
    </section>
  </main>
</template>

<script>
import Unit from "../units/Unit.vue";
import ProjectOverviewTable from "../units/ProjectOverviewTable.vue";
import ProjectKeyInformation from "../units/ProjectKeyInformation.vue";
import UnitCurrentStage from "../units/UnitCurrentStage.vue";

export default {
  data() {
    return {};
  },
  props: ["units"],
  components: {
    Unit,
    ProjectOverviewTable,
    ProjectKeyInformation,
    UnitCurrentStage,
  },
  methods: {
    deleteUnit(id) {
      this.$emit("deleteUnit", id);
    },
  },
};
</script>

<style scoped>
.project-units-container {
  position: relative;
  --unit-card-width: 500px;
  --unit-container-maxheight: 560px;
  width: 100%;
  justify-self: center;
  display: grid;
  grid-template-columns: repeat(auto-fit, var(--unit-card-width));
  gap: 1rem;
  border-radius: 4px;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.unit-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 4px;
  justify-content: space-between;
  max-height: var(--unit-container-maxheight);
  background: var(--white1);
  border: 1px solid var(--gray6);
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  overflow: hidden;
}

.unit-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 160px;
  max-height: 160px;
}

.u-current-stage {
  position: absolute;
  z-index: 1;
  top: 25%;
  left: 0;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
}

.unit-content-container {
  overflow: auto;
}

@media screen and (max-width: 1303px) {
  .project-units-container {
    justify-content: center !important;
  }
}

@media screen and (max-width: 539px) {
  .project-units-container {
    --unit-card-width: 90vmin;
    --unit-container-maxheight: 600px;
  }
}
</style>
