<template>
  <main class="unit-wrapper" @mouseleave="closeSecondaryMenu">
    <section class="unit-image-wrapper" @click="enterUnit(unit)">
      <img :src="unitImg" class="unit-image" />
      <!-- :style="[
          unit.unit_image
            ? `background-image: url('${unit.unit_image}')`
            : `background-image: var(--project-thumbnail); filter: hue-rotate(90deg);`,
        ]" -->
    </section>

    <section class="unit-info-wrapper">
      <div class="flex-column gap8">
        <p class="-stb">{{ unit.unit_reference }}</p>
        <p class="-stb">{{ unit.unit_title }}</p>
      </div>

      <div
        class="flex width100 gap8 align-end padding-block-4"
        title="Chosen Template"
      >
        <div
          class="template-icon"
          :class="`i-${unit.template.toLowerCase()}`"
        ></div>
        <p class="-xstb">{{ unit.template }}</p>
      </div>

      <p
        class="-ntb submenu-toggler"
        title="More options"
        @click="toggleSubmenu"
      >
        . . .
      </p>
      <section
        class="secondary-menu"
        v-show="unit.secondaryMenu"
        ref="secondaryMenu"
      >
        <div class="secondary-menu-item">
          <p class="-xstr">Unit Details</p>
        </div>
        <div
          class="secondary-menu-item delete-p"
          @click="
            [(modalConfig.state.action = 'delete'), (modalConfig.opened = true)]
          "
        >
          <p class="-xstr">Delete Unit</p>
        </div>
        <div class="secondary-menu-item" @click="unit.secondaryMenu = false">
          <p class="-xstr">Close</p>
        </div>
      </section>
    </section>
  </main>

  <Modal
    v-if="modalConfig.opened"
    :setModal="modalConfig.state.type"
    :action="modalConfig.state.action"
    :item="{
      title: `You're about to delete the unit: ${unit.unit_reference} - ${unit.unit_title}`,
      reference: unit.id,
      context: 'unit',
      msg: `Once deleted, it will be permanently removed and cannot be
                                    recovered. Do you want to proceed?`,
    }"
    @confirmAction="handleAction"
    @closeModal="modalConfig.opened = false"
  />
</template>

<script>
import { deleteUnit } from "../../services/requests/post/projects";
import Modal from "../../views/Modal.vue";
export default {
  components: { Modal },
  props: ["unit"],
  emits: ["deleteUnit"],
  data() {
    return {
      modalConfig: {
        opened: false,
        state: {
          type: "confirmAction",
          action: null,
        },
      },
    };
  },
  computed: {
    unitImg() {
      return this.unit.unit_image || require("@/assets/thumbnails/render.jpg");
    },
  },
  methods: {
    async enterUnit(unit) {
      await this.$store.dispatch("unitModule/unit", unit);
      console.log("unit set successfully: ", this.$store.state.unitModule.unit);
      await this.$store.dispatch(
        "setCurrentStage",
        this.$store.state.unitModule.unit.stage_value
      );
      await this.$store.dispatch("selectedStages", [
        this.$store.state.unitModule.unit.current_stage.id,
      ]);

      // console.warn(this.$store.state);

      await this.$nextTick();

      this.$router.push({ name: "MScope" });
    },
    async handleAction(id) {
      if (this.modalConfig.state.action === "delete") {
        await this.deleteUnit(id);

        this.modalConfig.state.action = "";
      }
    },
    async deleteUnit(id) {
      const res = await deleteUnit(id);
      if (res == 200) {
        this.$emit("deleteUnit", id);
      }

      this.modalConfig.opened = false;
    },
    toggleSubmenu() {
      this.unit.secondaryMenu = !this.unit.secondaryMenu;
      console.log(this.unit);
    },
    closeSecondaryMenu() {
      if (!this.unit.secondaryMenu) return;

      //set timeout to allow for click event to fire
      setTimeout(() => {
        this.unit.secondaryMenu = false;
        this.$refs.secondaryMenu.classList.remove("slide-out");
      }, 200);

      this.$refs.secondaryMenu.classList.add("slide-out");
    },
  },
};
</script>

<style scoped>
.unit-wrapper {
  display: flex;
}

.unit-image-wrapper {
  width: 260px;
  height: 100%;
  overflow: hidden;
  position: relative;
  isolation: isolate;
  z-index: 1;
}
.unit-image-wrapper:hover {
  cursor: pointer;
}

.unit-image {
  width: 100%;
  height: 100%;
  /* border-bottom-right-radius: 4px; */
  transition: 600ms;
  position: relative;
  z-index: 0;
  /* background-image: var(--project-thumbnail) !important; */
  /* background-image: url("https://cdn.discordapp.com/attachments/928228506939297852/1029693386346745866/blocoA.jpg"); */
  object-fit: cover;
  object-position: center;
  /* filter: hue-rotate(90deg); */
}

.unit-image-wrapper:hover::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "Click to enter unit";
  font-family: "Montserrat-Bold";
  color: #ffffff !important;
  background: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  animation: slide-up 400ms;
}

.unit-image-wrapper:hover > .unit-image {
  scale: 1.1;
}

.unit-info-wrapper {
  position: relative;
  max-width: 160px;
  padding-inline: 8px;
  padding-block: 4px;
  text-align: left;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 8px;
}

.template-icon {
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.submenu-toggler {
  user-select: none;
  cursor: pointer;
  width: max-content;
  padding-inline: 2px;
  transition: 200ms;
}

.submenu-toggler:hover {
  letter-spacing: 1px;
}

.secondary-menu {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 8px;
  padding: 1px;
  background: rgb(226, 116, 236);
  background: linear-gradient(
    29deg,
    rgb(255, 88, 88) 10%,
    rgb(245, 143, 182) 50%,
    rgb(226, 116, 236) 90%
  );
  border-radius: 2px;
  cursor: pointer;
  animation: slide-in 200ms;
}

.slide-out {
  animation: slide-out 200ms forwards;
}

.secondary-menu-item {
  padding-inline: 8px;
  padding-block: 4px;
  /* background: linear-gradient(
    34deg,
    rgba(17, 17, 17, 1) 0%,
    rgba(51, 51, 51, 1) 100%
  ); */
  background: rgba(0, 0, 0, 0.8);
  margin-bottom: 1px;
  text-align: center;
  color: var(--white1);
}

.secondary-menu-item:last-child {
  margin-bottom: 0px;
  color: var(--gray4);
}

.secondary-menu-item:hover {
  color: var(--secondary);
}

.delete-p:hover {
  color: salmon;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-120%);
  }

  to {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-120%);
  }
}

@keyframes fade-bg {
  from {
    background: rgba(0, 0, 0, 0);
  }

  to {
    background: rgba(0, 0, 0, 0.2);
  }
}

@media screen and (max-width: 499px) {
  .unit-image-wrapper {
    width: 224px;
  }
}

@media screen and (max-width: 374px) {
  .unit-image-wrapper {
    width: 180px;
    height: 144px;
    align-self: center;
  }

  @keyframes slide-in {
    from {
      transform: translateX(120%);
    }

    to {
      transform: translateX(0%);
    }
  }
}
</style>
