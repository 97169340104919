<template>
  <main class="main-container">
    <section class="project-settings flex-column align-start gap16">
      <p class="-xltb title">Project Details</p>

      <TabSelector :routerTabs="routerTabs" />

      <section class="width100 height100" style="padding-top: 16px">
        <router-view />
      </section>
    </section>
    <div class="unit-box">
      <ProjectDetailsUnit />
    </div>
  </main>
</template>

<script>
import TabSelector from "../../components/utils/TabSelector.vue";
import ProjectDetailsUnit from "../../components/projects/ProjectDetailsUnit.vue";
export default {
  components: {
    TabSelector,
    ProjectDetailsUnit,
  },
  data() {
    return {
      routerTabs: [
        {
          slug: "Project Details",
          name: "UpdateProjectDetails",
        },
        // {
        //   slug: "Security",
        //   name: "Projects",
        // },
      ],
    };
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  width: 100%;
  /* height: calc(100vh - 48px); */
}
.project-settings {
  width: 100%;
  min-height: 100%;
  /* position: fixed; */
  padding-inline: 10vw;
  padding-block: 8px;
  /* border-radius: 8px; */
  background: var(--white1);
  /* left: 0; */
  /* border: 1px solid red; */
  /* overflow: auto; */
}
.project-settings > p {
  font-family: "Montserrat-Bold";
  margin-bottom: 8px;
}

.unit-box {
  display: flex;
  /* position: absolute; */
  /* right: 0; */
  width: 30%;
  height: 100%;
  /* border: 1px solid red; */
}
@media screen and (max-width: 1029px) {
  .main-container {
    flex-flow: column;
    height: max-content;
  }
  .unit-box {
    display: flex;
    flex-flow: row;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .project-settings {
    padding-inline: 5vw;
    border-radius: 0;
  }
}

@media screen and (max-width: 399px) {
  .project-settings {
    padding-inline: 8px;
  }
}
</style>
