<template>
  <section class="unit-stage-container" :class="{ isHovered: unit.hovered }" :style="[
    { background: getStageColor(unit.stage_value) },
    { border: `1px solid ${getStageColor(unit.stage_value)}` },
  ]" @mouseenter="unit.hovered = true" @mouseleave="unit.hovered = false">
    <p class="-ntb" v-show="!unit.hovered">{{ unit.stage_value }}</p>
    <div :class="'slide-in'" v-show="unit.hovered">
      <p class="-stb">
        {{ unit.stage_value }}: {{ getStageName(unit.stage_value) }}
      </p>
    </div>
  </section>
</template>

<script>
import { getStageColor, getStageName } from "../../helpers/stageGetters";
export default {
  props: ["unit"],
  methods: {
    getStageColor(unitStage) {
      return getStageColor(unitStage);
    },
    getStageName(unitStage) {
      return getStageName(unitStage);
    },
  },
};
</script>

<style scoped>
.unit-stage-container {
  width: 24px;
  height: 100%;
  padding: 4px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: 320ms;
  backdrop-filter: blur(8px);
  user-select: none;
  cursor: pointer;
  overflow: hidden;
}

.isHovered {
  width: 220px;
  padding-inline: 8px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.slide-in {
  animation: slide-from-left 800ms;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.-ntb {
  animation: slide-from-left 800ms;
}

@keyframes slide-from-right {
  0% {
    opacity: 0;
    transform: translateX(120%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-from-left {
  0% {
    opacity: 0;
    transform: translateX(-120%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>