<template>
  <!-- :style="[
      selectedProject.imagePath
        ? `background-image: url('${selectedProject.imagePath}')`
        : `background-image: var(--project-thumbnail);`,
    ]" -->
  <section class="p-picture">
    <img :src="projectImg" />
    <i class="i-back-nav" @click="goBack"></i>
  </section>

  <section class="p-info gap4">
    <div class="width100 flex justify-space-between flex-wrap">
      <p class="-ntb -bold">
        {{ selectedProject.project_name }}
      </p>
      <p class="-ntb -bold">{{ selectedProject.group }}BIMMS</p>
    </div>

    <div class="flex-column width100">
      <p class="-stb -bold">{{ selectedProject.reference }}</p>
      <div class="aditional-info">
        <p class="-xstb"><i class="i-stack"></i>{{ unitsLength }} Units</p>
        <p class="-xstb">
          <i class="i-pin"></i> Porto, {{ selectedProject.proj_country }}
        </p>
      </div>
    </div>
    <p class="-stb -bold">Project lead</p>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["unitsLength"],
  data() {
    return {
      selectedProject: null,
    };
  },
  created() {
    this.selectedProject = JSON.parse(
      JSON.stringify(this.$store.state.project)
    );
  },
  methods: {
    async goBack() {
      await this.$router.push({ name: "Projects" });
    },
  },
  computed: {
    ...mapGetters(["user"]),
    projectImg() {
      return (
        this.$store.state?.project?.project_image ||
        require("@/assets/thumbnails/render.jpg")
      );
    },
  },
};
</script>

<style scoped>
.p-picture {
  position: relative;
  isolation: isolate;
  z-index: 0;
  height: 200px;
  /* min-height: 200px; */
  width: 240px;
  /* min-width: 240px; */
  border-radius: 10px;
  /* background-image: var(--project-thumbnail); */
  /* background-image: url("https://images.unsplash.com/photo-1574958269340-fa927503f3dd?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1648&q=80"); */
  /* background-position: center;
  background-repeat: no-repeat; */
  /* background-size: cover; */
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
  overflow: hidden;
}
.p-picture > img {
  position: relative;
  z-index: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.i-back-nav {
  transform: scale(2);
  position: absolute;
  z-index: 1;
  top: 16px;
  left: 16px;
  cursor: pointer;
  transition: transform 200ms ease;
}
.i-back-nav:hover {
  transform: scale(2.5);
}
.p-info {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  width: 240px;
}
.aditional-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-top: 5px;
  gap: 8px;
}

.c-btn:hover {
  background: var(--secondary-transp);
  color: var(--primary-transp);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}
p {
  display: flex;
}
i {
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
  transform: scale(1);
}

@media screen and (max-width: 1280px) {
  .aditional-info {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
</style>
